import { useState, useEffect } from 'react';
import { Await, Link } from 'react-router-dom'
import { FaCheck, FaBriefcase, FaBolt, FaRegComment, FaPlus, FaSnowplow, FaHands, FaGrinBeam, FaCheckDouble } from "react-icons/fa";
import { insertJob, editJob, db, deleteJob, getAppConfig, setAppConfig } from './../../data/db'
import { useLiveQuery } from "dexie-react-hooks";

import Swal from '../../data/swal';

export default function IndexPage() {



    const [addJobAnimateClass, setAddJobAnimateClass] = useState('');
    const [inputs, setInputs] = useState({
        addJobId: '',
        addJobTitle: '',
        addJobDescription: '',
        addJobRepeatId: 0,
        addJobOrder: 1,
        addJobColor: 'bg-sky-400',

        archiveTitle: '',
    })
    const [jobs, setJobs] = useState([])

    const [pageAction, setPageAction] = useState({
        modalAddJob: false,
        modalJobAction: false,// مدال مدیریت وضعیت کار
        jobTab: 'doing',
        firstAddJob: false,
        activeArchive: 0,
        repeatsModalDiv: false,
        repeatsModalEveryDay: false,
    })

    const [contents, setContents] = useState({
        jobAction: {},
        jobs: [],
        archives: [],
    })

    const [repeats, setRepeats] = useState([])


    useEffect(() => {
        reloadJobsDataList();
    }, [pageAction.jobTab])



    const changeTab = (tab) => {
        console.log('change tab', tab);
        setPageAction(pv => ({ ...pv, jobTab: tab }));
    }


    /**
     * اطلاعات را از دیتابیس بروز میکند
     * @param {int} withDelay 
     * @param {callback} callback 
     */
    const reloadJobsDataList = async (withDelay = 0, callback = false) => {

        // لود یادداشت برای خدا
        getAppConfig('for_god').then(res => {
            setContents(pv => ({ ...pv, forGod: res || '' }));
        })

        // لود آیدی  آرشیو
        // زمانی که از لیست ذخیره شده استفاده میکنیم
        getAppConfig('archiveActiveId').then(res => {
            setPageAction(pv => ({ ...pv, activeArchive: res }))
        })


        // ایا تاکنون کاری اضافه شده است یا خیر
        // فقط برای اولین بار 
        // کاربر این برای نمایش بهتر راهنمایی ها به کلاینت است
        getAppConfig('firstAddJob').then(res => {
            setPageAction(pv => ({
                ...pv, firstAddJob: res || false,
            }))
        })

        // لیست تمام کار ها دریافت می شود
        const get_jobs = await db.jobs.orderBy('order').toArray()

        // دریافت لیست آرشیو ها
        const archives = await db.archives.orderBy('id').desc().limit(10).toArray();

        // لیست آرشیو را ست میکند
        setContents(pv => ({ ...pv, archives: archives }))

        // متغییر تمام کار ها مقدار دهی میشود
        setJobs(get_jobs)

        // یک متغییر جدید برای نمایش لیست کار ها
        // فیلتر بر روی این متغییر اعمال میشود
        let show_jobs = get_jobs;




        // لیست قابل نمایش مقدار دهی میشود
        setTimeout(() => {

            // اگر نیاز به فیلتر لیست بر اساس تب بود
            if (pageAction.jobTab != 'all') {
                show_jobs = show_jobs.filter(job => job.status == pageAction.jobTab)
            }
            setContents(pv => ({ ...pv, jobs: show_jobs }))



            // reloadJobsDataList()
            if (callback != false) {
                callback({ jobs: get_jobs });
            }
        }, withDelay)
    }




    const addJob = async () => {

        if (inputs.addJobTitle.length < 3) {

            return;
        }

        setPageAction(pv => ({ ...pv, modalAddJob: false }))

        if (!inputs?.addJobId) {
            await insertJob({
                title: inputs.addJobTitle,
                description: inputs.addJobDescription,
                order: inputs.addJobOrder,
                color: inputs.addJobColor,
                status: 'doing',
                progress: 0,
            }, repeats);
        }
        else {
            await editJob(inputs?.addJobId, {
                title: inputs?.addJobTitle,
                description: inputs?.addJobDescription,
                order: inputs.addJobOrder,
                color: inputs.addJobColor,
            }, repeats);

            setInputs(pv => ({ ...pv, addJobId: '' }))
        }

        setAppConfig('firstAddJob', 1);
        reloadJobsDataList(0, res => {
            if (pageAction.activeArchive > 0) {
                db.archives.update(pageAction.activeArchive, { jobs: res.jobs })
            }
        })


        resetAddJobInput()
    }

    const resetAddJobInput = () => {
        setInputs(pv => ({
            ...pv,
            addJobTitle: '',
            addJobId: 0,
            addJobDescription: '',
            addJobOrder: 1,
            addJobColor: 'bg-sky-400'
        }))

        setRepeats([])
        setPageAction(pv => ({ ...pv, repeatsModalDiv: false }));
    }

    const doneJob = async (job, e, delay = 1200) => {

        // دریافت نوع وضعیت کار
        let status = e.target.checked ? 'done' : 'doing';

        // پیدا کردن ایندکس ایتم انتخابی از لیست تمام کارها
        const index = jobs.map(fjob => fjob.id).indexOf(job.id);


        if (status == 'doing') {
            job.progress = contents.jobAction.progress = 0;

            setContents(pv => ({ ...pv, jobAction: contents.jobAction }))
        }
        else {
            job.progress = contents.jobAction.progress = 100;
            setContents(pv => ({ ...pv, jobAction: contents.jobAction }))
        }

        if (index > -1) {

            // وضعیت کار تغییر میکند
            job.status = status;

            // اطلاعات جدید در لیست تمام کار ها بروز می شود
            setJobs([...jobs]);
        }

        // اطلاعات جدید در دیتابیس بروز می شود
        db.jobs.update(job.id, { status: status, progress: job.progress })


        // لیست جدید اطلاعات از دیتابیس دریافت می شود 
        reloadJobsDataList(delay, res => {
            if (pageAction.activeArchive > 0) {
                db.archives.update(pageAction.activeArchive, { jobs: res.jobs })
            }
        })
    }


    const toggleAddJobModal = (action = null, reset = false) => {
        if (reset) {
            resetAddJobInput()
        }

        if (action == null) {
            let show = !pageAction.modalAddJob;
            setPageAction(pv => ({ ...pv, modalAddJob: show }))
        }
        else {
            setPageAction(pv => ({ ...pv, modalAddJob: action }))
        }
    }

    const toggleJobActionModal = (action = null) => {
        if (action == null) {
            let show = !pageAction.modalJobAction;
            setPageAction(pv => ({ ...pv, modalJobAction: show }))
        }
        else {
            setPageAction(pv => ({ ...pv, modalJobAction: action }))
        }
    }

    const changeProgress = (action) => {
        if (action == '+' && contents.jobAction.progress < 100) {
            contents.jobAction.progress = contents.jobAction.progress + 10;
            setContents(pv => ({ ...pv, jobAction: contents.jobAction }))
        }
        else if (action == '-' && contents.jobAction.progress > 0) {
            contents.jobAction.progress = contents.jobAction.progress - 10;
            setContents(pv => ({ ...pv, jobAction: contents.jobAction }))
        }

        if (contents.jobAction.progress == 100 && contents.jobAction.status == 'doing') {
            contents.jobAction.status = 'done';
            setContents(pv => ({ ...pv, jobAction: contents.jobAction }))
        }
        else if (contents.jobAction.progress < 100) {
            contents.jobAction.status = 'doing';
            setContents(pv => ({ ...pv, jobAction: contents.jobAction }))
        }
        db.jobs.update(contents.jobAction.id, {
            progress: contents.jobAction.progress,
            status: contents.jobAction.status,
        })

        reloadJobsDataList(200, res => {
            if (pageAction.activeArchive > 0) {
                db.archives.update(pageAction.activeArchive, { jobs: res.jobs })
            }
        })

    }

    const editJobModal = async () => {
        setPageAction(pv => ({ ...pv, modalJobAction: false }))
        setPageAction(pv => ({ ...pv, modalAddJob: true }))

        console.log(contents?.jobAction);
        setInputs(pv => ({
            ...pv,
            addJobTitle: contents?.jobAction?.title,
            addJobDescription: contents?.jobAction?.description,
            addJobId: contents?.jobAction?.id,
            addJobRepeatId: contents?.jobAction?.repeat_id,
            addJobOrder: contents?.jobAction?.order,
            addJobColor: contents?.jobAction?.color
        }))

        if (contents?.jobAction.repeat_id && contents?.jobAction.repeat_id > 0) {
            console.log('#editJobModal: init repeat', contents?.jobAction.repeat_id);
            let repeat = await db.repeats.where('id').equals(contents?.jobAction.repeat_id).first();
            console.log('#editJobModal: repeat', repeat);

            if (repeat && repeat.repeats.length) {
                setRepeats(repeat.repeats);
                setPageAction(pv => ({ ...pv, repeatsModalDiv: true }))
            }
        }
        else {
            setRepeats([]);
            setPageAction(pv => ({ ...pv, repeatsModalDiv: false }))
        }
    }


    const openJobDialog = (job) => {
        setContents(pv => ({ ...pv, jobAction: job }))
        setPageAction(pv => ({ ...pv, modalJobAction: true }));
    }

    const deleteJobAction = () => {
        let options = {
            title: <p>{contents.jobAction.title} <b>حذف شود؟</b></p>,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: 'بله حذف شود',
            cancelButtonText: 'لغو'
        };

        if (contents.jobAction.repeat_id > 0) {
            options.text = 'این کار به صورت تکرار شونده ایجاد شده است پس از حذف به طور کامل حذف شده و دیگر تکرار نمی‌شود';
        }
        Swal.fire(options).then(async (res) => {
            if (res.isConfirmed) {
                await deleteJob(contents.jobAction.id)
                setPageAction(pv => ({ ...pv, modalJobAction: false }))
                reloadJobsDataList();
            }
            return false;
        })
    }

    const saveForGod = async (event) => {
        let value = event.target.value;
        // console.log('for god', value);
        setContents(pv => ({ ...pv, forGod: value }))
        await setAppConfig('for_god', value)
    }


    /**
     * تمام کار های لیست را ذخیره میکند
     */
    const archiveAllJob = async () => {
        const jobs = await db.jobs.toArray();
        db.archives.add({
            title: inputs.archiveTitle,
            jobs: jobs,
        });

        db.jobs.clear();

        await checkRepeatJob();

        reloadJobsDataList();

        setPageAction(pv => ({ ...pv, modalArchive: false }));
    }

    const checkRepeatJob = async () => {
        let repeats = await db.repeats.toArray();
        const date = new Date;
        const dayNumber = date.getDay();
        // const weekday = ["1", "2", "3", "4", "5", "6", "0"];
        console.log('# checkRepeatJob: dayNumber:', dayNumber + '_day');

        console.log('start checkRepeatJob', repeats);
        await Promise.all(repeats.map(async repeat => {
            let days = repeat.repeats;
            console.log('checkRepeatJob: map: days:', days);
            if (days.length == 1 && days[0] == 'every_day') {
                await addNewJobFromRepeat(repeat)
            }
            else {
                if (days.indexOf(dayNumber + '_day') > -1) {
                    await addNewJobFromRepeat(repeat)
                }
            }
        }))

        console.log('end checkRepeatJob');

    }

    const addNewJobFromRepeat = async (repeat) => {
        console.log('addNewJobFromRepeat');
        let new_job = {
            ...repeat,
            status: 'doing',
            progress: 0,
            repeat_id: repeat.id,
        };

        delete new_job.repeats;

        await insertJob(new_job, []);
    }

    const reloadArchiveConfirm = (archive_id) => {

        if (archive_id == 0) {
            if (pageAction.activeArchive != archive_id) {
                clearJobs(true);
            }
            setAppConfig('archiveActiveId', archive_id)
            setPageAction(pv => ({ ...pv, activeArchive: archive_id }))
            return;
        }


        let message = 'کارهای فعلی حذف می‌شود و لیست انتخابی جایگزین آن می‌شود. درصورت لزوم کار های فعلی خود را ذخیره کنید';
        let icon = 'warning';

        if (contents?.jobs?.length == 0) {
            message = "لیست انتخابی بازیابی شود؟"
            icon = 'info';
        }

        Swal.fire({
            title: 'بازیابی لیست',
            text: message,
            showCancelButton: true,
            focusConfirm: true,
            showDenyButton: true,
            confirmButtonText: 'بازیابی شود',
            cancelButtonText: 'لغو',
            denyButtonText: 'حذف شود',
            icon: icon
        })
            .then(async (res) => {
                if (res.isConfirmed) {
                    reloadArchive(archive_id)
                }
                else if (res.isDenied) {
                    deleteArchiveById(archive_id)
                }
                return false;
            })
    }

    const reloadArchive = async (archive_id) => {
        const archive = await db.archives.where('id').equals(parseInt(archive_id)).first();
        await db.jobs.clear();
        console.log('reload ar:', archive_id, archive.jobs);
        for (const job of archive.jobs) {
            await db.jobs.add(job)
        }
        await setAppConfig('archiveActiveId', archive.id)
        reloadJobsDataList();
        setPageAction(pv => ({ ...pv, activeArchive: archive.id }))
    }

    const deleteArchiveById = async (archive_id) => {
        const row = await db.archives.where('id').equals(parseInt(archive_id)).delete();
        console.log('delete archive:', archive_id, ' row:', row, '  ', parseInt(archive_id));
        reloadJobsDataList();
    }

    const clearJobs = (force = false) => {
        if (force == false) {
            Swal.fire({
                text: 'لیست تمام کارها حذف شود؟',
                showCancelButton: true,
                showConfirmButton: true,
                cancelButtonText: 'لغو',
                confirmButtonText: 'پاکسازی کارها',
            })
                .then(res => {
                    if (res.isConfirmed) {

                        // لیست کار های امروز را حذف میکند
                        db.jobs.clear();

                        // شناسه آرشیو را صفر میکند
                        setAppConfig('archiveActiveId', 0)
                            .then(async () => {

                                await checkRepeatJob();

                                // اطلاعات جدید لود می شود
                                reloadJobsDataList()

                                // متغییر شناسه آرشیو مقدار دهی می شود
                                setPageAction(pv => ({ ...pv, activeArchive: 0 }))
                            })
                    }
                })
        }
        else {

            // لیست کار ها حذف می شود
            db.jobs.clear();

            // شناسه آرشیو را صفر میکند
            setAppConfig('archiveActiveId', 0)
                .then(() => {

                    // اطلاعات جدید لود می شود
                    reloadJobsDataList()

                    // متغییر شناسه آرشیو مقدار دهی می شود
                    setPageAction(pv => ({ ...pv, activeArchive: 0 }))
                })
        }
    }

    const toggleModalArchive = (action) => {
        if (action == 'close') {
            setPageAction(pv => ({ ...pv, modalArchive: false }))
        }
        else {
            const d = new Date()
            const date = new Intl.DateTimeFormat('fa-IR', { weekday: 'short', day: 'numeric', month: 'short' }).format(d);
            setInputs(pv => ({ ...pv, archiveTitle: date }))
            setPageAction(pv => ({ ...pv, modalArchive: true }))
        }
    }

    const addRepeat = (type) => {
        if (repeats.indexOf(type) > -1) {
            setRepeats(repeats.filter(a => a !== type))
        }
        else {
            setRepeats([...repeats, type])
        }
    }



    return (
        <div className=''>

            <div className='bg-neutral p-4 '>

            </div>

            <div className='w-auto max-w-[1000px] mx-auto'>
                <div className='p-2 sxxx:p-4' >
                    <div className='bg-secondary rounded-lg text-white p-2'>
                        <div className='flex flex-row gap-2 items-center text-white/70'>
                            <FaHands size={20} />
                            <div className='text-sm font-extrabold '>خدایا امروز سپاسگزارم بابت:</div>
                        </div>

                        <div className='myghalam'>
                            <textarea onChange={event => saveForGod(event)} value={contents.forGod} rows="2" className='text-white/80 text-center text-2xl font-bold textarea textarea-secondary textarea-ghost w-full mt-2'></textarea>
                        </div>
                    </div>

                </div>


                <div className='p-2 sxxx:p-4'>


                    <div className='flex flex-col sxx:flex-row gap-2 sxxx:gap-4'>

                        <div className='flex flex-col sxx:flex-row gap-1 sxx:gap-2 items-center flex-2'>
                            <button onClick={() => toggleModalArchive('open')} className='btn flex-1 w-full sxx:w-auto btn-primary'>ذخیره کارها</button>
                            {/* <button className='btn flex-1 w-full sxx:w-auto btn-primary'>حذف کار ها</button> */}

                        </div>

                        <div className='items-center flex-1 flex gap-2'>

                            <select value={pageAction.activeArchive} onChange={e => reloadArchiveConfirm(e.target.value)} className='select select-primary flex-1'>
                                <option value={0}  >امروز</option>
                                {contents.archives.map((archive) => {
                                    return (
                                        <option key={archive.id} value={archive.id}>{archive.title}</option>
                                    )
                                })}
                            </select>

                        </div>
                    </div>

                    <div className='bg-primary rounded-lg p-2 mt-2'>
                        <div className='flex justify-between items-center'>

                            <div className='text-white/70 font-extrabold flex items-center ' >
                                <FaSnowplow className="transform -scale-x-100 -mt-2" size={30} />
                                <div className=''>لیست کارها</div>
                            </div>

                            <div role="tablist" className="tabs tabs-boxed mx-auto hidden sm:block">
                                <a role="tab" onClick={() => changeTab('all')} className={`tab ${pageAction.jobTab == 'all' ? 'tab-active' : ''}`}>همه</a>
                                <a role="tab" onClick={() => changeTab('doing')} className={`tab ${pageAction.jobTab == 'doing' ? 'tab-active' : ''}`}>درحال انجام</a>
                                <a role="tab" onClick={() => changeTab('done')} className={`tab ${pageAction.jobTab == 'done' ? 'tab-active' : ''}`}>پایان یافته</a>
                            </div>

                            <div className='flex gap-2 items-center'>


                                <button onClick={() => clearJobs()} className='btn btn-sm btn-accent'>پاکسازی</button>


                                <button onClick={() => toggleAddJobModal(true, true)} className={` btn btn-sm btn-secondary flex ${addJobAnimateClass}`}>
                                    <div>افزودن</div>
                                    <FaPlus size={20} />
                                </button>


                            </div>

                        </div>
                        <div className='flex mt-6 '>
                            <div role="tablist" className="tabs tabs-boxed mx-auto block sm:hidden duration-100">
                                <a role="tab" onClick={() => changeTab('all')} className={`tab ${pageAction.jobTab == 'all' ? 'tab-active' : ''}`}>همه</a>
                                <a role="tab" onClick={() => changeTab('doing')} className={`tab ${pageAction.jobTab == 'doing' ? 'tab-active' : ''}`}>درحال انجام</a>
                                <a role="tab" onClick={() => changeTab('done')} className={`tab ${pageAction.jobTab == 'done' ? 'tab-active' : ''}`}>پایان یافته</a>
                            </div>
                        </div>



                        <div className=' rounded-lg mt-4 p-2 flex flex-col gap-5'>



                            {pageAction.jobTab == 'doing' && (contents.jobs?.length === 0 && pageAction?.firstAddJob) ? (<div className="text-center text-white/70 font-bold text-lg border-4 border-white/50 border-dashed rounded-xl p-4 mt-6">
                                <div ><FaGrinBeam className='mx-auto' size={40} /></div>
                                <div className='mt-4'>لیست درحال انجام خالی است</div>
                            </div>) : ''}

                            {pageAction.jobTab == 'all' && (contents.jobs?.length === 0) ? (<div className="text-center text-white/70 font-bold text-lg border-4 border-white/50 border-dashed rounded-xl p-4 mt-6">
                                {pageAction?.firstAddJob && <div ><FaGrinBeam className='mx-auto' size={40} /></div>}
                                <div className='mt-4'>لیست خالی است کار جدیدی ایجاد کنید</div>
                            </div>) : ''}

                            {pageAction.jobTab == 'done' && (contents.jobs?.length === 0) ? (<div className="text-center text-white/70 font-bold text-lg border-4 border-white/50 border-dashed rounded-xl p-4 mt-6">
                                <div><FaBriefcase className='mx-auto' size={40} /></div>
                                <div className='mt-4'>هیچ کار انجام شده‌ای وجود ندارد</div>
                            </div>) : ''}


                            {pageAction.jobTab == 'doing' && (contents.jobs?.length === 0 && pageAction?.firstAddJob == false) ? (<div className="text-center  font-bold  border-4 border-white/50 border-dashed rounded-xl p-4 mt-6">
                                <div className='mt-4 text-lg text-white/80'>هنوز فعالیت یا کاری اضافه نکرده اید</div>
                                <div className='mt-2 text-white/60'>با کلیک روی افزودن می‌توانید یک کار جدید ثبت کنید</div>
                            </div>) : ''}

                            {contents.jobs?.map(job => {
                                return (

                                    <div key={job.id} className={` bg-white relative rounded-xl flex justify-between items-center`}>

                                        <div className='duration-500 bg-success/50 right-0 top-0 opacity-100 z-0 h-full absolute rounded-xl' style={{ width: job.progress + '%' }}></div>

                                        <div onClick={() => openJobDialog(job)} className='relative z-10 font-bold flex-1 hover:bg-base-300 duration-200 rounded-xl hover:cursor-pointer h-full  py-4 pr-3 pl-1 flex items-center justify-between' style={{ maxWidth: "calc(100% - 58px)" }}>
                                            <div className={`absolute right-2 -top-3 ${job.color || 'bg-sky-400'} text-white rounded-md px-2 pt-0.5 text-sm`} >#{job.order}</div>
                                            <div className=' truncate'>{job.title}</div>
                                            <div>
                                                {(job.description && job.description.length > 0) ? (<FaRegComment className='text-3xl' />) : ''}
                                            </div>

                                        </div>
                                        <div className='z-10 flex gap-2 items-center pl-1'>
                                            <label htmlFor={`checkbox-${job.id}`} className={`${job.status == 'done' ? 'bg-success' : 'bg-gray-100'}  p-2 flex justify-center  items-center rounded-xl border border-success border-opacity-80 mr-2`}>
                                                <input onChange={e => doneJob(job, e)} checked={job.status == 'done' ? true : false} type="checkbox" className=' checkbox checkbox-lg checkbox-success ' id={`checkbox-${job.id}`} />
                                            </label>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>

                {(
                    // این مدال برای افزودن کار جدید است

                    <dialog className={`modal ${pageAction.modalAddJob ? 'modal-open' : ''}`} id="CreateNewJob">
                        <div className="modal-box">
                            <form method="dialog">
                                <button onClick={() => toggleAddJobModal(false, true)} className=" btn btn-sm btn-circle btn-ghost absolute end-2 top-2">✕</button>
                            </form>

                            {/* <h3 className="font-bold text-lg">افزدن یاداوری جدید</h3> */}

                            <div className='mt-4 flex flex-col gap-2'>

                                <div className='form-control'>
                                    <label htmlFor="">عنوان فعالیت یا کار</label>
                                    <input maxLength={45} value={inputs.addJobTitle} onInput={e => setInputs(pv => ({ ...pv, addJobTitle: e.target.value }))} type="text" className='input input-primary focus:scale-[101%] focus:mt-1 duration-200' />
                                </div>
                                <div className='form-control'>
                                    <label htmlFor="">توضیحات(اختیاری)</label>
                                    <textarea maxLength={140} value={inputs.addJobDescription} onInput={e => setInputs(pv => ({ ...pv, addJobDescription: e.target.value }))} type="text" className='textarea textarea-primary  focus:scale-[101%] focus:mt-1 duration-200' ></textarea>
                                </div>

                                <div className='grid grid-cols-1 sxx:grid-cols-2 gap-2'>

                                    <div className='form-control'>
                                        <label htmlFor="">انتخاب اولویت</label>
                                        <select className='select select-primary font-bold' value={inputs.addJobOrder} onChange={e => setInputs(pv => ({ ...pv, addJobOrder: e.target.value }))} >
                                            <option value="1">اولویت 1</option>
                                            <option value="2">اولویت 2</option>
                                            <option value="3">اولویت 3</option>
                                            <option value="4">اولویت 4</option>
                                            <option value="5">اولویت 5</option>
                                        </select>

                                    </div>
                                    <div className='form-control'>
                                        <label htmlFor="">انتخاب رنگ</label>
                                        <select className='select select-primary font-bold' value={inputs.addJobColor} onChange={e => setInputs(pv => ({ ...pv, addJobColor: e.target.value }))} >
                                            <option className='text-sky-400' value="bg-sky-400">آبی آسمانی</option>
                                            <option className='text-red-600' value="bg-red-600">قرمز</option>
                                            <option className='text-green-600' value="bg-green-600">سبز</option>
                                            <option className='text-gray-700' value="bg-gray-700">تیره</option>
                                        </select>

                                    </div>

                                </div>


                                <div >
                                    {(((inputs.addJobId > 0 && inputs.addJobRepeatId>0) || !inputs.addJobId) ? (

                                        <div className='form-control mt-4'>
                                            <label htmlFor="repeats" className='flex active:scale-105 duration-200 items-center gap-2 cursor-pointer bg-gray-50 p-2 border-2 rounded-lg'>
                                                <input checked={pageAction.repeatsModalDiv} onChange={e => { setPageAction(pv => ({ ...pv, repeatsModalDiv: e.target.checked })) }} type="checkbox" name="" className='checkbox checkbox-primary' id="repeats" />
                                                تکرار
                                            </label>
                                        </div>


                                        
                                    ) : '')}

                                    {
                                        ((inputs.addJobId > 0 && inputs.addJobRepeatId>0) || !inputs.addJobId) &&
                                        pageAction.repeatsModalDiv ? (

                                            <div className='mt-2 grid grid-cols-2 sxxx:grid-cols-3 sm:grid-cols-4  gap-2'>
                                                <button onClick={() => addRepeat('every_day')} className={`btn ${repeats.indexOf('every_day') > -1 ? 'btn-info' : ''}`} disabled={(repeats.indexOf('every_day') < 0 && repeats.length)}>هر روز</button>
                                                <button onClick={() => addRepeat('6_day')} className={`btn ${repeats.indexOf('6_day') > -1 ? 'btn-info' : ''}`} disabled={repeats.indexOf('every_day') > -1}>شنبه‌ها</button>
                                                <button onClick={() => addRepeat('1_day')} className={`btn ${repeats.indexOf('1_day') > -1 ? 'btn-info' : ''}`} disabled={repeats.indexOf('every_day') > -1}>یک شنبه‌ها</button>
                                                <button onClick={() => addRepeat('2_day')} className={`btn ${repeats.indexOf('2_day') > -1 ? 'btn-info' : ''}`} disabled={repeats.indexOf('every_day') > -1}>دو شنبه‌ها</button>
                                                <button onClick={() => addRepeat('3_day')} className={`btn ${repeats.indexOf('3_day') > -1 ? 'btn-info' : ''}`} disabled={repeats.indexOf('every_day') > -1}>سه شنبه‌ها</button>
                                                <button onClick={() => addRepeat('4_day')} className={`btn ${repeats.indexOf('4_day') > -1 ? 'btn-info' : ''}`} disabled={repeats.indexOf('every_day') > -1}>چهار شنبه‌ها</button>
                                                <button onClick={() => addRepeat('5_day')} className={`btn ${repeats.indexOf('5_day') > -1 ? 'btn-info' : ''}`} disabled={repeats.indexOf('every_day') > -1}>پنج شنبه‌ها</button>
                                                <button onClick={() => addRepeat('0_day')} className={`btn ${repeats.indexOf('0_day') > -1 ? 'btn-info' : ''}`} disabled={repeats.indexOf('every_day') > -1}>جمعه‌ها</button>


                                            </div>
                                        ) : ''
                                    }


                                </div>



                                <div className='flex gap-4 mt-8'>
                                    <button disabled={inputs.addJobTitle.length < 3} onClick={addJob} className='btn btn-primary w-32'>ذخیره</button>
                                    <button onClick={() => toggleAddJobModal(false, true)} className='btn btn-base-200'>لغو</button>
                                </div>

                            </div>

                        </div>
                    </dialog>
                )}

                {(
                    <dialog className={`modal ${pageAction.modalArchive ? 'modal-open' : ''}`} id="CreateNewJob">
                        <div className="modal-box">
                            <form method="dialog">
                                <button onClick={() => toggleModalArchive('close')} className=" btn btn-sm btn-circle btn-ghost absolute end-2 top-2">✕</button>
                            </form>

                            <h3 className="font-bold text-lg">ذخیره کردن لیست کارها</h3>

                            <div className='mt-4'>
                                <p className='text-center border rounded-xl p-2'>لیست بایگانی شده را می‌توانی بعدا دوباره بازیابی کنید</p>
                            </div>

                            <div className='mt-4 flex flex-col gap-2'>

                                <div className='form-control'>
                                    <label htmlFor="">عنوان بایگانی</label>
                                    <input maxLength={30} value={inputs.archiveTitle} onInput={e => setInputs(pv => ({ ...pv, archiveTitle: e.target.value }))} type="text" className='input input-primary' />
                                </div>


                                <div className='flex gap-4 mt-8'>
                                    <button disabled={inputs.archiveTitle.length < 3} onClick={archiveAllJob} className='btn btn-primary w-32'>ذخیره</button>
                                    <button onClick={() => toggleModalArchive('close')} className='btn btn-base-200'>لغو</button>
                                </div>

                            </div>

                        </div>
                    </dialog>
                )}

                {/* مدال عملیات یک کار */}
                {/* زمانی که روی یک کار کلیک میکنیم باز میشود */}
                <dialog className={`modal ${pageAction.modalJobAction ? 'modal-open' : ''}`} id="CreateNewJob">
                    <div className="modal-box">
                        <form method="dialog">
                            <button onClick={() => toggleJobActionModal(false)} className=" btn btn-sm btn-circle btn-ghost absolute end-2 top-2">✕</button>
                        </form>

                        <div className='font-bold border-4 border-dashed p-4 rounded-xl bg-base-200/30 mt-6'>
                            <div className='font-bold text-center text-lg text-wrap'>{contents.jobAction.title}</div>
                            <div className='text-wrap mt-2 d text-center text-md text-gray-700 whitespace-nowrap'><p>{contents.jobAction.description}</p></div>

                        </div>



                        <div className='flex items-center justify-center font-bold  bg-base-200/30  border-4 border-dashed p-4 rounded-xl mt-8 relative'>
                            <div className='absolute z-20 rounded-xl top-0 right-3 flex justify-between -mt-5 items-center gap-4 w-fit'>
                                <button onClick={() => { changeProgress('-') }} className="btn btn-sm">-</button>
                                <div style={{ fontFamily: 'Arial' }} className='text-gray-500 bg-gray-200 rounded-lg px-2 text-center text-sm flex items-center  py-1'>{contents.jobAction.progress}%</div>
                                <button onClick={() => { changeProgress('+') }} className="btn btn-sm">+</button>

                            </div>

                            <div className='duration-200 bg-success/50 right-0 top-0 opacity-100 z-0 h-full absolute rounded-xl' style={{ width: contents.jobAction.progress + '%' }}></div>
                            <div className='text-gray-700/70 z-10'>وضعیت : </div>
                            <div className='text-gray-800 flex-1 z-10 text-center font-extrabold  justify-center flex gap-1 items-center '>
                                <div className="flex gap-2 items-center justify-between  flex-1">
                                    <div className='flex gap-2 items-center flex-1 justify-center'>

                                        {contents.jobAction.status == 'doing' ?
                                            <>
                                                <div>درحال انجام </div>
                                                <div><FaBolt /></div>
                                            </>

                                            :
                                            <>
                                                <div>انجام شده </div>
                                                <div><FaCheck /></div>
                                            </>
                                        }
                                    </div>

                                    <div className='flex'>
                                        <input checked={contents.jobAction.status == 'done'} onChange={event => doneJob(contents.jobAction, event, 0)} type="checkbox" name="" className='checkbox checkbox-lg checkbox-success' id="" />
                                    </div>

                                </div>

                            </div>
                        </div>




                        {/* <div className='mt-4'>
                        <div className='text-gray-700/70 font-bold ' >تغییر وضعیت به :</div>
                        <div className='hover:scale-[101%] active:scale-[101%] duration-200 w-full bg-success shadow-md shadow-gray-500 rounded-xl py-10 flex flex-col items-center gap-4'>
                            <div className='font-bold text-white text-2xl'>انجام شده</div>
                            <div><FaCheckDouble className='text-white text-3xl' /></div>
                        </div>
                    </div> */}

                        <div className='flex items-center justify-center gap-3 mt-8'>
                            <button onClick={() => toggleJobActionModal(false)} className='btn btn-base-200'>بستن</button>
                            <button onClick={() => editJobModal()} className='btn btn-secondary w-48 font-bold flex-1'>ویرایش</button>
                            <button onClick={deleteJobAction} className='btn btn-error'>حذف</button>
                        </div>



                    </div>
                </dialog>


            </div>




            {/* <div className='text-center text-xl mt-10 font-bold'> این برنامه برای ذخیره لیست کار های شما ایجاد شده است</div>

            <div className="text-center mt-8">
                <div>
                    <Link to={'/jobs/list'} className='btn btn-primary'>شروع به کار</Link>
                </div>
            </div> */}
        </div>
    );

}