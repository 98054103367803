import { ReactComponent as IconPlus } from './../../icons/IconPlus.svg'

export default function BigWithIcon({ title, click }) {

    return (
        <button onClick={click} className="p-5 border-4 border-secondary rounded-xl w-72 btn-secondary bg-base-200 hover:bg-base-300 shadow-md shadow-base-200 hover:scale-[101%] duration-200">
            <div className='flex justify-center flex-col'>

                <div className='text-secondary font-bold'>{title}</div>

                <div className='text-center mt-8'><IconPlus className='w-8 text-secondary mx-auto' /></div>
            </div>
        </button>
    );

}