import React,{lazy} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'
import './assets/fonts/vazir/vazirmatn_rd_fontface.css'
import './assets/fonts/myghalam/myghalam_rd_fontface.css'

import reportWebVitals from './reportWebVitals';
import IndexPage from './pages/index/index';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Profile from './pages/profile/profile'

import MainPage from './pages/jobs/main'
import UserListPage from './pages/jobs/list/list'


const Test = lazy(()=>import('./pages/test/test'))
// const Profile = lazy(()=>import('./pages/profile/profile'))

const router = createBrowserRouter(
  [
    {
      path: "/jobs",
      element: <MainPage />,
      children:[
        {
          path:'list',
          element:<UserListPage/>
        },
      ]
      
    },
    {
      path:'/',
      element:<IndexPage/>
    },
    {
      path:'/help',
      element: <Test/>,
      errorElement:<div>Error</div>
    }
  ]
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}></RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ("serviceWorker" in navigator) {
  // Register a service worker hosted at the root of the
  // site using the default scope.
  navigator.serviceWorker.register("/sw.js").then(
    (registration) => {
      console.log("Service worker registration succeeded:", registration);
    },
    (error) => {
      console.error(`Service worker registration failed: ${error}`);
    },
  );
} else {
  console.error("Service workers are not supported.");
}

