import Dexie from 'dexie';

export const db = new Dexie('brojob.ir');
db.version(1).stores({
  jobs: '++id, title, description, priority, status, progress, order, color, repeat_id, start_at, end_at',
  repeats: '++id, repeats, title, description, priority, status, progress, order, color, start_at, end_at',
  archives: '++id, title, jobs',
  configs: '&name, value',
});
window.db = db;

export async function insertJob(ob, repeats) {
  if(repeats.length){
    let rob = {...ob, repeats:repeats};
    let repeat_id = await db.repeats.add(rob);
    ob.repeat_id = repeat_id;
  }
  return await db.jobs.add(ob)
}

export async function editJob(id, ob, repeats) {
  console.log('# UPDATE JOBS id:', id);
  
  let job = await db.jobs.where('id').equals(id).first();

  if(job && job.repeat_id > 0){
    let rob = {...ob, repeats:repeats};
    let rows = await db.repeats.update(job.repeat_id, rob)
    console.log('# UPDATE REPEATS id:', job.repeat_id, ' rows:',rows );

  }
  
  return await db.jobs.update(id, ob)
}

export async function deleteJob(job_id) {
  console.info('# DELETE JOB ACTION id:', job_id);

    
  let job = await db.jobs.where('id').equals(job_id).first();

  if(job && job.repeat_id > 0){
    await db.repeats.delete(job.repeat_id);
  }

  return await db.jobs.delete(job_id)
}

const GetAppConfig = async function (name) {
  let config = await db.configs.where('name').equals(name).first();

  if (!config) {
    return null;
  }
  else {
    return config?.value || false;
  }
}

export const getAppConfig = GetAppConfig;

export async function setAppConfig(name, value) {
  let config = await GetAppConfig(name);
  console.log('# SET APP CONFIG name:', name, ' value:', value);
  if (config === null) {
    console.log('# INSERT VALUE');
    return await db.configs.add({ name: name, value: value })
  }
  else {
    console.log('# UPDATE VALUE');
    return await db.configs.update(name, { value: value })
  }
}