import { useState } from "react";
import PrimaryButton from "../../../components/jobs/button/primary";
import SecondaryButton from "../../../components/jobs/button/secondary";
import BigWithIcon from './../../../components/jobs/button/BigWithIcon'
export default function List() {

    const [list, setList] = useState([]);


    let emptyListContent = (<div className="flex justify-center flex-col items-center">
        <div className="text-gray-700/80 font-bold text-xl">شما هیچ کار یا فعالیتی ندارید</div>
        <div className="text-gray-700/70 font-bold text-lg">با کلیک روی دکمه زیر یک فعالیت جدید ایجاد کنید</div>
        <div className="mt-8"><BigWithIcon click={createNewJobHandler} title="افزدن فعالیت یا کار جدید" /></div>
    </div>);

    const listContent = list.map((number) => {
        return <div className="text-black" key={number}>{number}</div>
    });

    const modalCreateNewJob = (
        <dialog className="modal" id="CreateNewJob">
            <div className="modal-box">
                <form method="dialog">
                    <button className=" btn btn-sm btn-circle btn-ghost absolute end-2 top-2">✕</button>
                </form>

                <h3 className="font-bold text-lg">افزدن یاداوری جدید</h3>
                <p className="py-4">Press ESC key or click the button below to close</p>


            </div>
        </dialog>
    );

    function createNewJobHandler() {
        document.getElementById('CreateNewJob').showModal()

        // let next = 0;
        // if (list.length) {
        //     next = list[list.length - 1] + 1;
        // }

        // setList([...list, next])
    }

    function openModalCreateNewJob() {
    }


    return (
        <div className=''>
            <div className="flex p-4 bg-neutral ">
                <SecondaryButton onclick={createNewJobHandler} title='ایجاد کار جدید' />
            </div>

            {modalCreateNewJob}
            {list.length ? listContent : emptyListContent}




        </div>
    );
}